import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UserPlanFeatureContext,
  UserPlanFeatureResponse,
  UserPlanGuard,
} from '@softbrik/data/models';
import { AppService } from '@softbrik/data/services';
import { TranslateService } from '@softbrik/translate';
import { Observable } from 'rxjs';

type WithLimit<T> = T & { limit: UserPlanFeatureResponse };

@Component({
  selector: 'softbrik-feature-select',
  templateUrl: './feature-select.component.html',
  styleUrls: ['./feature-select.component.scss'],
})
export class FeatureSelectComponent<T extends Object> implements OnInit {
  @ViewChild('select', { static: true }) select: ElementRef<HTMLSelectElement>;
  @Input('brik') brik: string;
  @Input('guards') guards: UserPlanGuard[] = ['modal', 'message'];
  @Input('action') action: string;
  @Input('denyMessage') denyMessage: string;
  @Input('groupMessage') groupMessage?: string;
  @Input('items') itemsRaw: Observable<T[]>;
  @Input('itemExtractor') itemExtractor: (item: T) => {
    label: string;
    key: string;
  };
  @Input('limitKey') limitKey = 'id';
  @Input('valueKey') valueKey = 'id';
  @Input('context') context?: Observable<UserPlanFeatureContext>;
  @Output('allow') allow = new EventEmitter<{ item: T }>();

  featureEnabled: boolean;

  items: WithLimit<T>[];
  isLoading = true;
  limit: UserPlanFeatureResponse | undefined;
  showModal: boolean;

  constructor(private app: AppService, private t: TranslateService) {}

  get someNotAllowed() {
    return Boolean(this.limit);
  }

  get translateParams() {
    return {
      item: this.limit?.data.item?.label,
    };
  }

  get useMessageGuard() {
    return this.guards.includes('message');
  }

  get useModalGuard() {
    return this.guards.includes('modal');
  }

  get useDisableGuard() {
    return this.guards.includes('disable');
  }

  get useHideGuard() {
    return this.guards.includes('hide');
  }

  async ngOnInit() {
    this.featureEnabled = this.app.featureEnabled('plans');

    if (!this.featureEnabled) {
      this.items = (await this.itemsRaw.toPromise()) as WithLimit<T>[];
      this.limit = { allowed: true };
      this.isLoading = false;
      return;
    }

    let emittedOnce = false;
    this.items = (await this.itemsRaw.toPromise()).map((item: T) => {
      const limited = item as WithLimit<T>;
      const extracted = this.itemExtractor(item);
      limited.limit = this.app.canUse(
        this.brik,
        this.action,
        {
          ...this.context,
          item: extracted,
          itemKey: item[extracted.key],
        },
        { emit: !emittedOnce }
      );
      emittedOnce = true;
      if (!this.limit && !limited.limit.allowed) {
        this.limit = limited.limit;
      }
      return item;
    }) as WithLimit<T>[];

    this.isLoading = false;

    this.emitSignal();
  }

  private async emitSignal() {
    if (this.useMessageGuard && this.limit && this.groupMessage) {
      this.app.analytics.emit({
        name: 'plan: upgrade message shown',
        payload: {
          message: await this.t.get(this.groupMessage),
        },
      });
    }
  }

  onChange(itemValue: any) {
    const item = this.items.find((item) => item[this.valueKey] == itemValue);

    this.limit = item.limit;

    if (!this.featureEnabled || item?.limit.allowed) {
      this.allow.emit({
        item,
      });
      return;
    }

    this.allow.emit({
      item: null,
    });

    if (this.useModalGuard) {
      this.showModal = true;
    }
  }

  async goToUpgrade() {
    this.app.goToUpgrade({
      message: await this.t.get(this.denyMessage, this.limit.data),
      data: this.limit.data,
    });
  }

  closeModal() {
    this.showModal = false;
    this.select.nativeElement.value = '';
    this.allow.emit({
      item: null,
    });
  }
}
