import { appendStyles } from '@softbrik/shared/helpers';

appendStyles(`
  .brik-container {
    --size: 64px;
  }
  .brik {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }

  .brik-name {
    font-weight: 700;
  }

  .brik-card {
    width: var(--size);
    height: 6var(--size);;
    overflow: hidden;
    padding: 0.5rem;
    border-radius: 8px;
    margin-right: 1rem;
    transition: all ease-in-out 0.25s;
  }

  .brik-card:hover {
    cursor: pointer;
    transform: scale(1.05) translateY(0.125rem);
    box-shadow: 0px 12px 32px -16px var(--color-grey);
  }

  .brik-icon {
    margin: auto;
    overflow: hidden
  }

  .brik-icon img{
    width: 100%;
  }

  @media (min-width: 768px) {
    .brik-container {
      --size: 160px;
    }
    .brik {
      flex-direction: column;
      width: var(--size);
    }
    .brik-card {
      width: var(--size);
      height: 6var(--size);;
      display: flex;
      padding: 1rem;
      justify-content: center;
      align-items: center;
      margin-right: 0;
    }
    .brik-name {
      margin-top: 10px;
    }
  }
`);

export class BrikCard extends HTMLElement {
  public static observedAttributes = ['brikid', 'briktitle'];
  public id: string;
  public title: string;

  static get boundAttributes() {
    return ['brikid', 'briktitle'];
  }

  get brikid() {
    return this.hasAttribute('brikid');
  }

  set brikid(input) {
    this.setAttribute('brikid', input.toString());
  }

  get briktitle() {
    return this.hasAttribute('briktitle');
  }

  set briktitle(input) {
    this.setAttribute('briktitle', input.toString());
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (name == 'brikid') {
      this.id = newValue;
    }

    if (name == 'briktitle') {
      this.title = newValue;
    }

    this.innerHTML = `
      <div class="brik-container">
        <div class="brik has-text-centered">
          <div class="brik-card">
            <div class="brik-icon">
              <img src="assets/icons/brik-${this.id}.svg" alt="${this.title}">
            </div>
          </div>
          <span class="brik-name">${this.title}</span>
        </div>
      </div>
    `;
  }
}

customElements.define('softbrik-brik-card', BrikCard);
