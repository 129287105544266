import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AppService } from '@softbrik/data/services';

@Injectable({ providedIn: 'root' })
export class LayoutGuard implements CanActivate {
  constructor(private app: AppService) {}

  canActivate({ data }: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    // page layout default: 'default'
    this.app.page.layout = data.layout || 'default';
    // for 'plain' layout also disable sidebar and topbar by default
    this.app.page.sidebar =
      this.app.page.layout !== 'plain' && data.sidebar !== false;
    this.app.page.topbar =
      this.app.page.layout !== 'plain' && data.topbar !== false;
    // possibly override the defaults
    this.app.page.sidebar =
      data.sidebar !== undefined ? data.sidebar : this.app.page.sidebar;
    this.app.page.topbar =
      data.topbar !== undefined ? data.topbar : this.app.page.topbar;

    // this guard always allows navigation
    return true;
  }
}
