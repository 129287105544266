export const environment = {
  production: true,
  branch: 'master',
  bugsnagApiKey: '121ea2a1a1400f165e54cb2ce7365249',
  api: 'https://dev-api.softbrik.com',
  allo: 'https://co04sjnlg4.execute-api.eu-west-1.amazonaws.com/prod',
  stak: 'https://jcbhwywf1j.execute-api.eu-west-1.amazonaws.com/prod',
  trak: 'https://hqvo6esq71.execute-api.eu-west-1.amazonaws.com/prod',
  file: 'https://e9c96a0r61.execute-api.eu-west-1.amazonaws.com/prod',
  contacts: 'https://sqhuqfzqcj.execute-api.eu-west-1.amazonaws.com/prod',
  trust: 'https://p0ejgucpm8.execute-api.eu-west-1.amazonaws.com/prod',
  health: 'https://ID.execute-api.eu-west-1.amazonaws.com/prod',
  survey: 'https://5o257os7p0.execute-api.eu-west-1.amazonaws.com/prod',
  ws: 'wss://6838chna5f.execute-api.eu-west-1.amazonaws.com/dev',
  export: 'https://6c7057fmed.execute-api.eu-west-1.amazonaws.com/dev/surveys',
};
