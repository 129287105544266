import admin_ from './admin';
import allo_ from './allo';
import contact_ from './contact';
import dashboard_ from './dashboard';
import file_ from './file';
import stak_ from './stak';
import survey_ from './survey';
import trust_ from './trust';

export const admin = admin_;
export const allo = allo_;
export const contact = contact_;
export const dashboard = dashboard_;
export const file = file_;
export const stak = stak_;
export const survey = survey_;
export const trust = trust_;

export const faqs = [
  ...admin_,
  ...allo_,
  ...contact_,
  ...dashboard_,
  ...file_,
  ...stak_,
  ...survey_,
  ...trust_,
];
