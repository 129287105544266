import adminFeatures from './admin';
import trustFeatures from './trust';
import surveyFeatures from './survey';
import stakFeatures from './stak';

export default [
  ...adminFeatures,
  ...trustFeatures,
  ...surveyFeatures,
  ...stakFeatures,
];
