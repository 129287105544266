import { BrikId, UserPlanFeatureHandler } from '@softbrik/data/models';
import { maxHandler } from './utils';

const features: UserPlanFeatureHandler[] = [
  {
    brik_id: BrikId.Survey,
    name: 'surveys create',
    type: 'max',
    can: maxHandler,
  },
];

export default features;
