import { appendStyles } from '@softbrik/shared/helpers';

appendStyles(`
.channel-icon {
  display: inline-flex;
  width: 24px;
  height: 24px;
  font-weight: 400;
  font-size: 0.85rem;
  line-height: 1.25;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
}
`);

declare type DrawMode = 'fill' | 'outline';

// This is intensionally not created "smarter"
// to allow for purge css to spot used classes
const BRIK_CLASS_MAP = {
  admin: 'channel-icon--admin',
  allo: 'channel-icon--allo',
  contact: 'channel-icon--contact',
  file: 'channel-icon--file',
  stak: 'channel-icon--stak',
  trust: 'channel-icon--trust',
  unknown: 'channel-icon--unknown',
};

const BRIK_CODE_MAP = {
  admin: '<img src="/assets/icons/brik-admin.svg" alt="Admin" />',
  stak: '<img src="/assets/icons/brik-stak.svg" alt="Stak" />',
  trust: '<img src="/assets/icons/brik-trust.svg" alt="Trust" />',
  contact: '<img src="/assets/icons/brik-contact.svg" alt="Contact" />',
  admin_stak: '<img src="/assets/icons/brik-admin-stak.svg" alt="Admin" />',
  file: '<img src="/assets/icons/brik-file.svg" alt="File" />',
  unknown: '<i class="fa fa-question"></i>',
};

export class BrikChannelIcon extends HTMLElement {
  public static observedAttributes = ['brik', 'draw-mode'];

  get brik(): string {
    return this.getAttribute('brik');
  }

  set brik(value: string) {
    this.setAttribute('brik', value);
  }

  get drawMode(): DrawMode {
    const value = this.getAttribute('draw-mode');

    return value === 'fill' || value === 'outline' ? value : 'fill';
  }

  set drawMode(value: DrawMode) {
    this.setAttribute('draw-mode', value);
  }

  connectedCallback() {
    this.render();
  }

  render() {
    const modeClass =
      this.drawMode === 'fill'
        ? 'channel-icon--filled'
        : 'channel-icon--outlined';
    const brikClass = BRIK_CLASS_MAP[this.brik];
    const brikCode = BRIK_CODE_MAP[this.brik];

    this.innerHTML = `<span class="channel-icon ${modeClass} ${brikClass}" title="${this.brik}">${brikCode}</span>`;
  }
}

customElements.define('softbrik-channel-icon', BrikChannelIcon);
