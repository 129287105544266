import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'softbrik-play-button',
  templateUrl: './play-button.component.html',
  styleUrls: ['./play-button.component.scss'],
})
export class PlayButtonComponent implements OnInit {
  @ViewChild('player', { static: true }) player: ElementRef<HTMLMediaElement>;
  @Input('audio-url-provider') public audioUrlProvider: () => Promise<
    string | null
  >;
  @Output('error') public errorEmitter = new EventEmitter();
  audioUrl: string | null | undefined;
  playbackState: 'idle' | 'playing' | 'loading' | 'errored' = 'idle';

  constructor() {}

  ngOnInit(): void {}

  togglePlayback() {
    if (this.playbackState === 'playing') {
      this.stopPlayback();
    } else {
      this.startPlayback();
    }
  }

  async startPlayback() {
    if (this.playbackState === 'errored') return;
    try {
      this.playbackState = 'loading';
      const url = await this.getUrl();
      if (!url) {
        this.playbackState = 'errored';
        return;
      }
      this.player.nativeElement.src = url;
      await this.player.nativeElement.play();
      this.player.nativeElement.onpause = this.stopPlayback.bind(this);
      this.player.nativeElement.onended = this.onPlaybackEnded.bind(this);
      this.playbackState = 'playing';
    } catch (err) {
      console.log(err);
      if (['NotSupportedError', 'NotFoundError'].includes(err.name)) {
        this.playbackState = 'errored';
        this.errorEmitter.emit({
          message: 'File not found or not supported',
          error: err,
        });
      } else {
        this.playbackState = 'errored';
        this.errorEmitter.emit({ message: 'Error playing file', error: err });
      }
    }
  }

  stopPlayback() {
    this.player.nativeElement.pause();
    this.playbackState = 'idle';
    this.player.nativeElement.currentTime = 0;
  }

  onPlaybackEnded() {
    this.playbackState = 'idle';
    this.player.nativeElement.currentTime = 0;
  }

  private async getUrl(): Promise<string | null> {
    if (typeof this.audioUrl === 'undefined') {
      this.audioUrl = (await this.audioUrlProvider()) || null;
    }
    return this.audioUrl;
  }
}
