<div class="dashboard container is-fluid">
  <h3
    class="mt-4 title is-5"
    translate="dashboard.Welcome, {customer}"
    [translateParams]="{ customer: customerName }"
    (click)="startTour()"
  ></h3>

  <ng-template #loading>
    <div class="dashboard__loader animate-pulse" translate>common.Loading</div>
  </ng-template>

  <div *ngIf="briksAll | async; else loading" class="briks">
    <div class="briks__group">
      <ng-container *ngFor="let brik of briks | async">
        <softbrik-brik-card
          (click)="openBrik(brik.brik_id)"
          [joyrideStep]="brik.brik_id"
          [brikid]="brik.brik_id"
          [briktitle]="'briks.' + brik.description | translate"
          [title]="
            'joyride.tours.dashboard.' + brik.brik_id + '.title' | translate
          "
          [text]="
            'joyride.tours.dashboard.' + brik.brik_id + '.text' | translate
          "
          [stepPosition]="brik.brik_id === 'trust' ? 'right' : 'bottom'"
        >
        </softbrik-brik-card>
      </ng-container>
    </div>

    <hr />

    <div class="briks__group">
      <softbrik-brik-card
        *ngFor="let brik of briksAdmin | async"
        (click)="openBrik(brik.brik_id)"
        [joyrideStep]="brik.brik_id"
        [brikid]="brik.brik_id"
        [briktitle]="'briks.' + brik.description | translate"
        [title]="
          'joyride.tours.dashboard.' + brik.brik_id + '.title' | translate
        "
        [text]="'joyride.tours.dashboard.' + brik.brik_id + '.text' | translate"
        stepPosition="right"
      >
      </softbrik-brik-card>
    </div>
  </div>
</div>

<div *ngIf="isHelpQuestion" class="help-section-align">
  <div class="help-section-content">
    <img src="assets/icons/brik-light.svg" />
    <h4>Check our common questions</h4>
    <ul>
      <li>
        <div class="dropdown is-hoverable">
          <div class="dropdown-trigger">
            <button
              class="button"
              aria-haspopup="true"
              aria-controls="dropdown-menu4"
            >
              <span>How to use this Platform?</span>
              <span class="icon is-small">
                <i class="fa fa-angle-down" aria-hidden="true"></i>
              </span>
            </button>
          </div>
          <div class="dropdown-menu" id="dropdown-menu4" role="menu">
            <div class="dropdown-content">
              <div class="dropdown-item">
                <p>
                  <strong>Softbrik platform</strong> manages all business
                  operations easily for you. <strong>To use</strong> a Brik,
                  <strong>click on it from this main dashboard</strong>. They
                  are very intuitive to use, and we have video tutorials for
                  you. You can also get support from us anytime by voice
                  messages.
                </p>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li>
        <div class="dropdown is-hoverable">
          <div class="dropdown-trigger">
            <button
              class="button"
              aria-haspopup="true"
              aria-controls="dropdown-menu4"
            >
              <span>How can I understand this platform easily?</span>
              <span class="icon is-small">
                <i class="fa fa-angle-down" aria-hidden="true"></i>
              </span>
            </button>
          </div>
          <div class="dropdown-menu" id="dropdown-menu4" role="menu">
            <div class="dropdown-content">
              <div class="dropdown-item">
                <p>
                  We have an extensive
                  <a
                    href="https://softbrik.com/softbrik-academy/"
                    target="_blank"
                    >Softbrik Academy</a
                  >
                  with <strong>video tutorials</strong> for the various Briks
                  according to your service tier. <br />
                  <br />
                  <span class="mr-4"
                    ><a
                      href="https://softbrik.com/courses/trust-brik-course/"
                      target="_blank"
                      >Trust Brik</a
                    ></span
                  >
                  <span class="mr-4"
                    ><a
                      href="https://softbrik.com/courses/stak-brik-course/"
                      target="_blank"
                      >Stak Brik</a
                    ></span
                  >
                  <span
                    ><a
                      href="https://softbrik.com/courses/survey-brik-course/"
                      target="_blank"
                      >Survey Brik</a
                    ></span
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li>
        <div class="dropdown is-hoverable">
          <div class="dropdown-trigger">
            <button
              class="button"
              aria-haspopup="true"
              aria-controls="dropdown-menu4"
            >
              <span>How can I find more detailed Brik specific Help?</span>
              <span class="icon is-small">
                <i class="fa fa-angle-down" aria-hidden="true"></i>
              </span>
            </button>
          </div>
          <div class="dropdown-menu" id="dropdown-menu4" role="menu">
            <div class="dropdown-content">
              <div class="dropdown-item">
                <p>
                  Enter a Brik and click on this Help icon within that Brik to
                  find Brik specific usage instructions to support you.
                </p>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li>
        <div class="dropdown is-hoverable">
          <div class="dropdown-trigger">
            <button
              class="button"
              aria-haspopup="true"
              aria-controls="dropdown-menu4"
            >
              <span>How can I ask for Help not covered in these sections?</span>
              <span class="icon is-small">
                <i class="fa fa-angle-down" aria-hidden="true"></i>
              </span>
            </button>
          </div>
          <div class="dropdown-menu" id="dropdown-menu4" role="menu">
            <div class="dropdown-content">
              <div class="dropdown-item">
                <p>
                  You can drop a text or voice message to us anytime
                  <a
                    href="https://sb.softbrik.com/p/feedback?customer=8795a8ac-05f6-11ea-8d71-362b9e155667&business=support&language=en&form=481"
                    target="_blank"
                    >with this link</a
                  >
                  and we will get in touch to support you!
                </p>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>

    <div class="help-button-container">
      <button class="button is-outlined" (click)="closeHelpQuestion()">
        Continue Here
      </button>
      <button class="button is-success" (click)="getStartedLink()">
        Start Training
      </button>
    </div>
  </div>
</div>

<div *ngIf="!isHelpQuestion" class="help-section-align">
  <a class="lamp-icon" (click)="callHelpSection()">
    <img src="assets/icons/brik-light.svg" />
  </a>
</div>
