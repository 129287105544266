import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as pkg from '../../../../package.json';
import { environment } from '../environments/environment';
import {
  AppService,
  TrustService,
  StakService,
  FileService,
  ContactService,
  SurveyService,
  MenuItem,
  NavService,
} from '@softbrik/data/services';
import { translate as t } from '@softbrik/translate';
import { Subscription } from 'rxjs';

const { version } = pkg;

// Routes for which we do not perform sandbox check
const SANDBOX_EXCEPTIONS = [
  '/login',
  '/trust-feedback',
  '/p/feedback',
  '/stak-reply',
  '/p/reply',
  '/survey-submission',
  '/p/survey',
  '/feedback-signup',
  '/p/signup',
  '/signup',
];

@Component({
  selector: 'softbrik-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  public title = 'Softbrik';

  @HostBinding('class.with-sidebar') withSidebar: boolean;
  @HostBinding('class.is-plain') plainLayout: boolean;

  constructor(
    public app: AppService,
    public trust: TrustService,
    public stak: StakService,
    public file: FileService,
    public contact: ContactService,
    public survey: SurveyService,
    public router: Router,
    public nav: NavService
  ) {
    this.app.API_LINK = environment.api;
    this.contact.API_LINK = environment.contacts;
    this.file.API_LINK = environment.file;
    this.stak.API_LINK = environment.stak;
    this.survey.API_LINK = environment.survey;
    this.trust.API_LINK = environment.trust;

    localStorage.setItem('APP_API_LINK', this.app.API_LINK);
    localStorage.setItem('CONTACT_API_LINK', this.contact.API_LINK);
    localStorage.setItem('FILE_API_LINK', this.file.API_LINK);
    localStorage.setItem('STAK_API_LINK', this.stak.API_LINK);
    localStorage.setItem('SURVEY_API_LINK', this.survey.API_LINK);
    localStorage.setItem('TRUST_API_LINK', this.trust.API_LINK);

    this.app.version = version;
    this.app.branch = environment.branch;

    this.withSidebar = this.app.page.sidebar;
    this.plainLayout = this.app.page.layout === 'plain';

    this.app.user.subscribe((user) => {
      if (user) {
        this.handleMenu();
        this.handleSandbox();
      }
    });
  }

  private sidebarS$: Subscription;

  ngOnInit() {
    this.sidebarS$ = this.nav.navigationSidebar.subscribe({
      next: (items) => {
        this.withSidebar = items.length > 0;
      },
    });
  }

  ngOnDestroy() {
    this.sidebarS$?.unsubscribe();
  }

  handleMenu() {
    this.nav.setTopNavigation([
      {
        name: t('nav.Home'),
        link: '/',
        linkMethod: 'js',
        slot: 'left',
        type: 'icon',
        data: 'fa fa-home',
      },
      {
        name: t('nav.User menu'),
        slot: 'right',
        type: 'icon',
        linkMethod: 'none',
        data: 'fa fa-user',
        values: [
          {
            name: t('nav.Settings'),
            link: 'account/settings',
            type: 'text-icon',
            data: 'fa fa-cog',
          },
          {
            type: 'spacer',
            class: 'is-hidden-touch',
          },
          {
            name: t('nav.Log out'),
            type: 'text-icon',
            data: 'fa fa-sign-out',
            event: 'logout',
          },
        ],
      },
      // {
      //   name: t('nav.Help'),
      //   slot: 'right',
      //   type: 'icon',
      //   data: 'fa fa-book',
      //   event: 'showHelp',
      // },
    ]);
  }

  handleSandbox() {
    const path = window.location.pathname;

    if (SANDBOX_EXCEPTIONS.includes(path)) {
      return;
    }

    // Update domain_verified for the components that cannot access the sandbox observable
    // This can be removed when merged with dev, in dev the observable can be used in
    // the html for this component directly on the side menu.
    this.contact.isSandbox().subscribe((isSandbox) => {
      const wasSandbox =
        Number(window.sessionStorage.getItem('domain_verified') || '0') === 0;
      if (isSandbox) {
        window.sessionStorage.removeItem('domain_verified');
      } else {
        window.sessionStorage.setItem('domain_verified', isSandbox ? '0' : '1');
      }

      if (wasSandbox !== isSandbox) {
        window.location.reload();
      }
    });
  }

  toggleHelp() {
    this.app.showHelp = !this.app.showHelp;
  }

  logout() {
    this.app.logout();
  }

  navigateTop(event: CustomEvent) {
    this.app.navigate(event.detail);
  }

  navigateSidebar(item: MenuItem) {
    if (this.nav.sidebarHandler) {
      this.nav.sidebarHandler(item);
    } else {
      this.router.navigateByUrl(item.link);
    }
  }
}
