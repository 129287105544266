import { BrikId, PaginationRequest } from '@softbrik/data/models';

const paginationDefaults = Object.freeze({
  count: 5,
  offset: 0,
});

/**
 * Create params for doing a paginated query
 */
export function createParams(
  params: PaginationRequest = {},
  additional: Record<string, any> = {}
) {
  return Object.entries({ ...paginationDefaults, ...params, ...additional })
    .map(([key, value]) => {
      if (key === 'filter' && value) {
        return `filter=${encodeURIComponent(String(value))}`;
      }
      if (typeof value !== 'undefined' && value !== null && value !== '') {
        return `${key}=${value}`;
      }
    })
    .filter(Boolean)
    .join('&');
}

const DEV_DOMAINS = ['ngrok.io', 'devops.boutique', 'localhost'];
const DEV_DOMAIN_REGEX = new RegExp(`(.*)${DEV_DOMAINS.join('|')}$`);

export const isDevDomain = (domain: string) => DEV_DOMAIN_REGEX.test(domain);

export const getSubdomain = () =>
  isDevDomain(window.document.location.hostname)
    ? 'softdrinks'
    : window.document.location.hostname.split('.')[0];

export function extendFilters(
  params: PaginationRequest = {},
  additional:
    | ((filters: Record<string, any>) => Record<string, any>)
    | Record<string, any> = {}
): PaginationRequest {
  const filters = JSON.parse(params.filter || '{}');

  const filters_ =
    typeof additional === 'function'
      ? additional(filters)
      : { ...filters, ...additional };

  params.filter = JSON.stringify(filters_);

  return params;
}

export const isBrikId = (brikId: string): brikId is BrikId => {
  return Object.values(BrikId).includes(brikId as BrikId);
};
