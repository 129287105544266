import { BrikId, UserPlanFeatureHandler } from '@softbrik/data/models';
import { balanceHandler, denyAndHideAlias, maxHandler } from './utils';

const features: UserPlanFeatureHandler[] = [
  {
    brik_id: BrikId.Admin,
    name: 'users create',
    type: 'balance',
    can: denyAndHideAlias('yallo', balanceHandler),
  },
  {
    brik_id: BrikId.Admin,
    name: 'users create',
    type: 'max',
    can: denyAndHideAlias('yallo', maxHandler),
  },
];

export default features;
