const KEY_KEY = '_storage_keys';

let VERSION = '0.0.0';

export enum StorageType {
  LOCAL = 'local',
  SESSION = 'session',
}

type Storage = {
  version: string;
  keys: string[];
};

interface Adapter {
  getItem(key: string): string;
  setItem(key: string, value: string): void;
  removeItem(key: string): void;
}

interface Wrapper {
  expire: number;
  value: any;
}

/**
 * Handles reading and writing to a store through an adapter. It encodes the
 * value along with meta data such as expiry time.
 */
export class StorageHandler {
  private adapter: Adapter;

  constructor(storage: StorageType = StorageType.LOCAL) {
    switch (storage) {
      case StorageType.LOCAL:
        this.adapter = window.localStorage;
        break;
      case StorageType.SESSION:
        this.adapter = window.sessionStorage;
        break;
    }
  }

  getItem(key: string): any {
    const json = this.adapter.getItem(key);
    if (!json) return null;
    const wrapper: Wrapper = JSON.parse(json);
    if (wrapper.expire != 0 && wrapper.expire < Date.now()) {
      this.adapter.removeItem(key);
      return null;
    }
    return wrapper.value;
  }

  setItem(key: string, value: any, ttl: number = 0) {
    const wrapper: Wrapper = {
      expire: ttl === 0 ? 0 : Date.now() + ttl * 1000,
      value,
    };
    this.adapter.setItem(key, JSON.stringify(wrapper));
  }

  removeItem(key: string) {
    this.adapter.removeItem(key);
  }

  getAdapter() {
    return this.adapter;
  }
}

type KeyOpt = {
  /**
   * Time-To-Live in seconds
   */
  ttl: number;
  /**
   * Target a scope
   */
  scope: string;
  /**
   * Target a root store
   */
  root: string;
};

type KeyOptNoTtl = Omit<KeyOpt, 'ttl'>;

export interface KeyHandler {
  /**
   * Get an item from the store
   *
   * Optionally get from sub scope
   */
  getItem(key: string, options?: Partial<KeyOptNoTtl>): any;
  /**
   * Set an item in the store
   *
   * Optionally set ttl and scope
   */
  setItem(key: string, value: any, options?: Partial<KeyOpt>): void;
  /**
   * Remove item from store
   */
  removeItem(key: string, options?: Partial<KeyOptNoTtl>): void;
  /**
   * Signal the key handler that key is to me managed so that when clearing
   * storage this key will be cleared as well.
   *
   * This is useful for when the key handler cannot be used directly to manage
   * keys.
   *
   */
  touchItem(key: string, options?: Partial<KeyOptNoTtl>): void;
  /**
   * Get qualifed prefix of the store
   */
  getPrefix(): string;
}

/**
 * The key handler helper generate keys and store them through a storage
 * handler
 *
 * A useful feature is scoping. Scoping allows you to create a store that
 * "lives" under the parent store. E.g. if 'issues' is scoped to 'stak'
 * then when setting 'selected' the key will be `stak_issues_selected`.
 */
export class StorageKeyHandler implements KeyHandler {
  private prefix: string;
  private handler: StorageHandler;

  constructor(handler: StorageHandler, prefix: string) {
    this.handler = handler;
    this.prefix = prefix;
  }

  getItem(key: string, options?: Partial<KeyOptNoTtl>): any {
    return this.handler.getItem(
      this.qualifiedKey(this.scopeKey(key, options?.scope), options?.root)
    );
  }

  setItem(key: string, value: any, options?: Partial<KeyOpt>) {
    const qualifiedKey = this.qualifiedKey(
      this.scopeKey(key, options?.scope),
      options?.root
    );
    this.addStorageKey(qualifiedKey);
    this.handler.setItem(qualifiedKey, value, options?.ttl);
  }

  removeItem(key: string, options?: Partial<KeyOptNoTtl>) {
    this.handler.removeItem(
      this.qualifiedKey(this.scopeKey(key, options?.scope), options?.root)
    );
  }

  getPrefix(): string {
    return this.prefix;
  }

  touchItem(key: string, options?: Partial<KeyOptNoTtl>) {
    const qualifiedKey = this.qualifiedKey(
      this.scopeKey(key, options?.scope),
      options?.root
    );
    this.addStorageKey(qualifiedKey);
  }

  clearStorageKeys() {
    this.getStorageKeys().forEach((key) => this.handler.removeItem(key));
  }

  scope(scope: string): KeyHandler {
    return {
      getItem: (key: string, options?: Partial<KeyOptNoTtl>) => {
        return this.getItem(key, { scope, ...options });
      },
      setItem: (key: string, value: any, options?: Partial<KeyOpt>) => {
        this.setItem(key, value, { scope, ...options });
      },
      removeItem: (key: string, options?: Partial<KeyOptNoTtl>) => {
        this.removeItem(key, { scope, ...options });
      },
      getPrefix: (): string => {
        return this.qualifiedKey(scope);
      },
      touchItem: (key: string, options?: Partial<KeyOptNoTtl>) => {
        this.touchItem(key, { scope, ...options });
      },
    };
  }

  private qualifiedKey(key: string, prefix?: string): string {
    const prefix_ = prefix || this.prefix;
    return `${prefix_}_${key}`.toLowerCase();
  }

  private scopeKey(key: string, scope?: string) {
    return scope ? `${scope}_${key}` : key;
  }

  private getStorageKeys(): string[] {
    return JSON.parse(this.handler.getAdapter().getItem(KEY_KEY) || initStore())
      .keys;
  }

  private addStorageKey(key: string) {
    const keys = Array.from(new Set([...this.getStorageKeys(), key]));
    this.handler.getAdapter().setItem(KEY_KEY, initStore({ keys }));
  }
}

/**
 * Create a new StorageHandler of either LOCAL or SESSION types.
 */
export function createStorageHandler(storage: StorageType) {
  return new StorageHandler(storage);
}

/**
 * Create a new KeyHandler
 */
export function createKeyHandler(
  prefix: string,
  handler: StorageType | StorageHandler
) {
  const handler_ =
    handler instanceof StorageHandler ? handler : new StorageHandler(handler);
  return new StorageKeyHandler(handler_, prefix);
}

function initStore(storage: Partial<Storage> = {}) {
  return JSON.stringify({
    version: VERSION,
    keys: [],
    ...storage,
  });
}

/**
 * Restores key stores and sets the current version
 */
export function restoreKeyStores(currentVersion?: string) {
  VERSION = currentVersion || VERSION;
  validateKeyStore(window.localStorage);
  validateKeyStore(window.sessionStorage);
}

function validateKeyStore(adapter: Adapter) {
  const version = getStorage(adapter)?.version;
  if (version === VERSION) return;
  clearAdapterKeys(adapter);
}

export function getStorage(adapter: Adapter): Storage {
  return JSON.parse(adapter.getItem(KEY_KEY) || initStore());
}

/**
 * Clear all keys, local and session alike
 *
 * Keys in whitelist will not be cleared
 */
export function clearKeys(whitelist: string[] = []) {
  clearAdapterKeys(window.localStorage, whitelist);
  clearAdapterKeys(window.sessionStorage, whitelist);
}

/**
 * Clears keys for a specific adapter
 */
function clearAdapterKeys(adapter: Adapter, whitelist: string[] = []) {
  const keys = getStorage(adapter).keys;
  const savedKeys = keys
    .map((key: string) => {
      if (whitelist.includes(key)) return key;
      adapter.removeItem(key);
    })
    .filter(Boolean);

  adapter.setItem(KEY_KEY, initStore({ keys: savedKeys }));
}
