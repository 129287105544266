<div class="app__content">
  <div class="columns">
    <div class="column">
      <softbrik-page-header
        title="{{ 'settings.language.Change language' | translate }}"
      ></softbrik-page-header>
    </div>
  </div>

  <div class="columns">
    <div class="column is-4-desktop is-12-touchable">
      <div class="panel">
        <a
          class="panel-block"
          [class.is-active]="lang.code === language"
          *ngFor="let lang of languages"
          (click)="select(lang.code)"
        >
          <span class="panel-icon">
            <img src="../../../assets/lng_flags/{{ lang.code }}.png" />
          </span>
          <span>{{ lang.label }}</span>
        </a>
      </div>

      <div class="buttons is-right">
        <button class="button is-success" (click)="save()" translate>
          common.Save
        </button>
        <button class="button is-text" (click)="cancel()" translate>
          common.Cancel
        </button>
      </div>
    </div>
  </div>
</div>
