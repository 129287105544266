import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AppService } from '@softbrik/data/services';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private app: AppService) {}

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.app.currentUser) {
      return true;
    }

    const queryParams = state.url
      ? {
          returnUrl: state.url,
        }
      : undefined;

    this.router.navigate(['/login'], { queryParams });

    return false;
  }
}
