<div class="container">
  <div class="has-text-weight-bold has-text-link">
    <span class="icon"><i class="fa fa-calendar"></i></span>
    <span *ngIf="value">
      <span
        class="is-clickable"
        tabindex="0"
        (click)="open = true"
        (keydown.space)="$event.preventDefault(); open = true"
        (keydown.enter)="$event.preventDefault(); open = true"
        >{{ this.valueFormatted }}</span
      >
      <span
        (click)="clear()"
        (keydown.space)="$event.preventDefault(); clear()"
        (keydown.enter)="$event.preventDefault(); clear()"
        tabindex="0"
        class="picker__clear ml-1 icon is-small"
        ><i class="fa fa-close"></i
      ></span>
    </span>
    <span
      *ngIf="!value"
      class="is-clickable"
      tabindex="0"
      (click)="open = true"
      (keydown.space)="$event.preventDefault(); open = true"
      (keydown.enter)="$event.preventDefault(); open = true"
      translate
      >{{ empty }}</span
    >
  </div>
  <div *ngIf="open" class="picker">
    <header class="picker__header">
      <div
        (click)="prev()"
        tabindex="0"
        class="is-size-7 is-clickable"
        (keydown.space)="$event.preventDefault(); prev()"
        (keydown.enter)="$event.preventDefault(); prev()"
      >
        <span class="icon"><i class="fa fa-chevron-left"></i></span>
        <span>{{ prevLabel }}</span>
      </div>
      <div
        class="picker__header__title"
        tabindex="0"
        (click)="today()"
        (keydown.space)="$event.preventDefault(); today()"
        (keydown.enter)="$event.preventDefault(); today()"
      >
        {{ currentLabel }}
      </div>
      <div
        (click)="next()"
        (keydown.space)="$event.preventDefault(); next()"
        (keydown.enter)="$event.preventDefault(); next()"
        tabindex="0"
        class="is-size-7 is-clickable"
      >
        <span>{{ nextLabel }}</span>
        <span class="icon"><i class="fa fa-chevron-right"></i></span>
      </div>
    </header>

    <div class="days">
      <div
        *ngFor="let weekday of weekdays"
        [key]="weekday.label"
        class="day-of-week"
        [class.day-of-week--weekend]="
          weekday.dayOfWeek === 0 || weekday.dayOfWeek === 6
        "
        [class.day-of-week--disabled]="
          calendar.config.disableDaysOfWeek.includes(weekday.dayOfWeek)
        "
      >
        {{ weekday.label }}
      </div>
      <div
        *ngFor="let day of calendar.days"
        [key]="day.date.toJSON()"
        class="day-container"
        [class.day-container--weekend]="
          day.dayOfWeek === 0 || day.dayOfWeek === 6
        "
      >
        <div
          class="day"
          (click)="select(day.date)"
          tabindex="0"
          (keydown.space)="$event.preventDefault(); select(day.date)"
          (keydown.enter)="$event.preventDefault(); select(day.date)"
          [class.day--other]="!day.inMonth"
          [class.day--valid]="day.state === 'valid'"
          [class.day--invalid]="day.state === 'invalid'"
          [class.day--today]="day.isToday"
          [class.day--selected]="day.selection"
          [class.day--selected-start]="day.selection === 'start'"
          [class.day--selected-end]="day.selection === 'end'"
          [class.day--selected-single]="day.selection === 'single'"
          [class.day--selected-included]="day.selection === 'included'"
        >
          {{ day.day }}
        </div>
      </div>
    </div>
  </div>
</div>
