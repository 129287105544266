import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
})
export class FilterHelpPipe implements PipeTransform {
  transform(items: any, config: any): any {
    return items && config.brik
      ? items.filter((item) => item.brik === config.brik)
      : items;
  }
}
