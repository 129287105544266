<div class="dropdown is-active animate-fade-in" [class.is-right]="alignRight">
  <div class="dropdown-menu" style="padding: 0">
    <div
      class="dropdown-content p-4 is-flex is-flex-direction-column has-background-warning-light"
    >
      <div
        (click)="closeModal()"
        class="delete is-small"
        style="position: absolute; right: -0.25rem; top: -0.5rem"
      ></div>
      <div translate [translateParams]="data">{{ message }}</div>
      <button (click)="goToUpgrade()" class="button is-success mt-4" translate>
        common.Upgrade
      </button>
    </div>
  </div>
</div>
