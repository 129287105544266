import { Component, Input, OnInit } from '@angular/core';
import {
  BrikId,
  UserPlanFeatureContext,
  UserPlanFeatureResponse,
  UserPlanGuard,
} from '@softbrik/data/models';
import { AppService } from '@softbrik/data/services';
import { TranslateService } from '@softbrik/translate';
import { Observable } from 'rxjs';

@Component({
  selector: 'softbrik-feature-block',
  templateUrl: './feature-block.component.html',
  styleUrls: ['./feature-block.component.scss'],
})
export class FeatureBlockComponent implements OnInit {
  @Input('brik') brik: string;
  @Input('action') action: string;
  @Input('guard') guard: 'hide' | 'message' = 'message';
  @Input('allowMessage') allowMessage?: string;
  @Input('denyMessage') denyMessage: string;
  @Input('context') context: Observable<UserPlanFeatureContext>;

  featureEnabled: boolean;

  limit: UserPlanFeatureResponse | undefined;

  constructor(private app: AppService, private t: TranslateService) {}

  get data() {
    return this.limit?.data || {};
  }

  get useMessageGuard() {
    return this.guard === 'message';
  }

  get useHideGuard() {
    return this.guard === 'hide';
  }

  get allowed() {
    return this.limit?.allowed;
  }

  get typedDenyMessage() {
    return `${this.denyMessage} ${this.limit.type}`;
  }

  async ngOnInit() {
    this.featureEnabled = this.app.featureEnabled('plans');

    if (!this.featureEnabled) {
      this.limit = { allowed: true };
      return;
    }

    if (!this.context) throw new Error('Cannot evaluate without context');

    this.context.subscribe((context) => {
      if (context) {
        this.limit = this.app.canUse(this.brik, this.action, context);

        this.emitSignal();
      }
    });
  }

  private async emitSignal() {
    if (this.useMessageGuard && !this.allowed) {
      this.app.analytics.emit({
        name: 'plan: upgrade message shown',
        payload: {
          message: await this.t.get(this.typedDenyMessage, this.data),
          data: this.data,
        },
      });
    }
  }

  async goToUpgrade() {
    this.app.goToUpgrade({
      message: await this.t.get(this.typedDenyMessage, this.data),
      data: this.data,
    });
  }
}
