import { appendStyles } from '@softbrik/shared/helpers';

appendStyles(`
  softbrik-notification-message {
    display: block;
    width: 360px;
    position: fixed;
    top: 0.75rem;
    right: 0.75rem;
    z-index: 9999;
    opacity: 0;
  }

  softbrik-notification-message.show-start {
    animation: fadeIn 500ms linear forwards;
  }

  softbrik-notification-message.show-end {
    animation: fadeOut 500ms linear forwards;
  }

  @keyframes fadeIn {
    0% { opacity: 0; }
    10% { opacity: 1; }
    100% { opacity: 1; transform: translateY(0px);}
  }

  @keyframes fadeOut {
    0% { opacity: 1; transform: translateY(0px);}
    100% { opacity: 0; transform: translateY(-30px);}
  }
`);

// @dynamic
export class NotificationMessage extends HTMLElement {
  public static observedAttributes = ['name', 'text', 'type', 'timeout'];

  static get boundAttributes() {
    return ['name', 'text', 'type'];
  }

  get name() {
    return this.hasAttribute('name');
  }

  set name(input) {
    this.setAttribute('name', input.toString());
  }

  get text() {
    return this.hasAttribute('text');
  }

  set text(input) {
    this.setAttribute('text', input.toString());
  }

  get type() {
    return this.hasAttribute('type');
  }

  set type(input) {
    this.setAttribute('type', input.toString());
  }

  get timeout() {
    return Number(this.getAttribute('timeout'));
  }

  set timeout(value: number) {
    this.setAttribute('timeout', String(value || 0));
  }

  public title: string;
  public description: string;
  public color: string;

  attributeChangedCallback(name: any, oldValue, newValue: any) {
    if (name == 'name') {
      this.title = newValue;
    }

    if (name == 'text') {
      this.description = newValue;
    }

    if (name == 'type') {
      if (newValue === 'success') {
        this.color = 'is-success';
      }

      if (newValue === 'error') {
        this.color = 'is-danger';
      }
    }

    if (this.title && this.color && this.timeout) {
      this.innerHTML = `
      <div class="notification ${this.color}">
        <p><strong>${this.title}</strong></p>
        <p>${this.description}</p>
      </div>
    `;

      this.classList.toggle('show-start');
      setTimeout(() => {
        this.classList.toggle('show-start');
        this.classList.toggle('show-end');
      }, this.timeout);
    }
  }
}

customElements.define('softbrik-notification-message', NotificationMessage);
