<div class="login-bg">
  <div class="login-ui">
    <h1 class="title is-3" translate>auth.Forgot password?</h1>

    <p translate>auth.Password reset instructions</p>

    <div class="mt-5">
      <form (submit)="reset()">
        <div class="field">
          <label class="label" for="email" translate>auth.E-mail</label>
          <div class="control">
            <input
              [(ngModel)]="email"
              id="email"
              type="email"
              name="email"
              class="input"
            />
          </div>
        </div>

        <div class="buttons">
          <button
            type="submit"
            class="button is-link is-fullwidth"
            [class.is-loading]="app.is_loading"
            translate
          >
            auth.Send reset e-mail
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
