<div class="login-bg">
  <div class="login-ui">
    <h1 class="title is-3" translate>auth.Login</h1>
    <form [formGroup]="form" (ngSubmit)="login()">
      <div class="field">
        <label class="label" translate>auth.E-mail</label>
        <input
          class="input is-info"
          type="email"
          name="email"
          formControlName="email"
        />
        <div
          *ngIf="f.email.errors?.required && f.email.touched"
          class="invalid-form-value"
          translate
        >
          auth.Please provide a valid e-mail
        </div>
        <div
          *ngIf="f.email.errors?.email && f.email.touched"
          class="invalid-form-value"
          translate
        >
          auth.Invalid e-mail
        </div>
      </div>
      <div class="field">
        <label class="label" translate>auth.Password</label>
        <input
          class="input is-info"
          type="password"
          formControlName="password"
          name="password"
        />
        <div
          *ngIf="f.password.errors?.required && f.password.touched"
          class="invalid-form-value"
          translate
        >
          auth.Password is required
        </div>
        <p class="has-text-right">
          <a
            (click)="app.navigate('forgot-password')"
            style="font-size: 0.8em"
            translate
          >
            auth.Forgot password?
          </a>
        </p>
      </div>
      <div class="control">
        <div
          *ngIf="apiError"
          class="mb-4 invalid-form-value"
          translate="auth.Login error: {reason}"
          [translateParams]="{ reason: apiError }"
        ></div>
        <button
          type="submit"
          class="button is-link is-fullwidth"
          [class.is-loading]="app.is_loading"
          translate
        >
          auth.Log in
        </button>
      </div>
    </form>

    <p class="pt-4 text-muted">Copyright © 2021 | Ver: {{ app.version }}</p>
  </div>
</div>
