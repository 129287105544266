import { BrikId, UserPlanFeatureHandler } from '@softbrik/data/models';
import {
  alwaysAllow,
  denyAndHideAlias,
  listIncludesHandler,
  maxHandler,
  truthyHandler,
} from './utils';

const features: UserPlanFeatureHandler[] = [
  {
    brik_id: BrikId.Trust,
    name: 'forms create',
    type: 'max',
    can: maxHandler,
  },
  {
    brik_id: BrikId.Trust,
    name: 'feedback update',
    type: 'boolean',
    can: denyAndHideAlias('yallo', alwaysAllow),
  },
  {
    brik_id: BrikId.Trust,
    name: 'feedback redirct',
    type: 'boolean',
    can: truthyHandler,
  },
  {
    brik_id: BrikId.Trust,
    name: 'feedback year',
    type: 'max',
    can: maxHandler,
  },
  {
    brik_id: BrikId.Trust,
    name: 'use language',
    type: 'list',
    can: listIncludesHandler,
  },
  {
    brik_id: BrikId.Trust,
    name: 'use design',
    type: 'list',
    can: listIncludesHandler,
  },
];

export default features;
