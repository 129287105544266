import {
  UserPlanFeature,
  UserPlanFeatureContext,
  UserPlanFeatureHandler,
  UserPlanFeatureResponse,
} from '@softbrik/data/models';

export const createResponse = (
  feature: UserPlanFeature,
  extend?: Partial<UserPlanFeatureResponse>
): UserPlanFeatureResponse => ({
  allowed: false,
  limitId: feature.id,
  type: feature.type,
  ...extend,
});

export const truthyHandler = (
  feature: UserPlanFeature,
  ctx: UserPlanFeatureContext
): UserPlanFeatureResponse =>
  ctx[feature.name]
    ? createResponse(feature, { allowed: true })
    : createResponse(feature, { allowed: false });

export const alwaysAllow = (
  feature: UserPlanFeature,
  _ctx: UserPlanFeatureContext
): UserPlanFeatureResponse => createResponse(feature, { allowed: true });

export const maxHandler = (
  feature: UserPlanFeature,
  ctx: UserPlanFeatureContext
): UserPlanFeatureResponse => {
  if (ctx.current === undefined)
    throw new Error('ctx.current is required for max handler');

  return ctx.current <= feature.max
    ? createResponse(feature, {
        allowed: true,
        data: {
          left: feature.max - ctx.current,
          max: feature.max,
          current: ctx.current,
        },
      })
    : createResponse(feature, {
        allowed: false,
        data: {
          left: Math.max(0, feature.max - ctx.current),
          max: feature.max,
          current: ctx.current,
        },
      });
};

export const listIncludesHandler = (
  feature: UserPlanFeature,
  ctx: UserPlanFeatureContext
): UserPlanFeatureResponse => {
  if (ctx.item === undefined)
    throw new Error('ctx.item is required for list includes handler');

  const all = feature.list?.[0] === 'ALL';

  return all || feature.list?.includes(ctx.item.key)
    ? createResponse(feature, {
        allowed: true,
        data: { item: ctx.item },
      })
    : createResponse(feature, {
        allowed: false,
        data: { item: ctx.item },
      });
};

export const balanceHandler = (
  feature: UserPlanFeature,
  _ctx: UserPlanFeatureContext
): UserPlanFeatureResponse => {
  return feature.balance > 0
    ? createResponse(feature, {
        allowed: true,
        data: {
          left: feature.balance,
          balance: feature.balance,
        },
      })
    : createResponse(feature, {
        allowed: false,
        data: {
          left: 0,
          balance: feature.balance,
        },
      });
};

export const denyAndHideAlias = (
  alias: string,
  handlerFn: UserPlanFeatureHandler['can']
) => {
  return (
    feature: UserPlanFeature,
    ctx: UserPlanFeatureContext
  ): UserPlanFeatureResponse => {
    if (ctx.customerAlias === alias) {
      return createResponse(feature, {
        allowed: false,
      });
    }

    return handlerFn(feature, ctx);
  };
};
