import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const userJson = localStorage.getItem('user');
    if (!userJson) {
      return next.handle(request);
    }

    const user = JSON.parse(userJson);
    const isLoggedIn = user && user.token;
    if (isLoggedIn) {
      const contentType = request.headers.getAll('Content-Type');
      // because of audio recorder or future headers otherwise app/json
      if (!contentType) {
        request = request.clone({
          setHeaders: {
            'Content-Type': 'application/json',
            Authorization: user.token,
          },
        });
      }
    }

    return next.handle(request);
  }
}
