<div
  tabindex="0"
  (click)="goBack()"
  (keydown.enter)="goBack()"
  (keydown.space)="goBack()"
  class="mr-5 back-button is-flex is-align-items-center"
  [title]="backTitle | translate"
>
  <div class="icon"><i class="mr-1 fa fa-chevron-left"></i></div>
  <div class="icon"><i class="fa fa-2x fa-table"></i></div>
</div>
