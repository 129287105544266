import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'softbrik-upgrade',
  templateUrl: './upgrade.component.html',
  styleUrls: ['./upgrade.component.scss'],
})
export class UpgradeComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
