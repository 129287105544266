import {
  AfterViewInit,
  Component,
  EmbeddedViewRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

@Component({
  selector: 'softbrik-action-portal',
  template: `
    <ng-template #actionPortal>
      <ng-content></ng-content>
    </ng-template>
  `,
  styles: [],
})
export class ActionPortalComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input('left') isLeft: string;
  @ViewChild('actionPortal') actionPortalRef: any;
  private disposeFn: () => void;
  private viewRef: EmbeddedViewRef<Record<string, unknown>>;

  constructor(private viewContainerRef: ViewContainerRef) {}

  ngOnInit(): void {
    // noop
  }

  ngAfterViewInit(): void {
    this.viewRef = this.viewContainerRef.createEmbeddedView(
      this.actionPortalRef
    );
    this.viewRef.detectChanges();

    const outlet = document.querySelector(
      typeof this.isLeft === 'undefined' ? '#actions-right' : '#actions-left'
    );

    this.viewRef.rootNodes.forEach((node) => outlet.appendChild(node));

    this.disposeFn = () => {
      // noop
    };
  }

  ngOnDestroy(): void {
    const index = this.viewContainerRef.indexOf(this.viewRef);
    if (index != -1) {
      this.viewContainerRef.remove(index);
    }
  }
}
