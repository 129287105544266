import { PipeTransform, Pipe } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { parseJSON, formatDistance, formatRelative } from 'date-fns';
import prettyBytes from 'pretty-bytes';
import { marked } from 'marked';

const parseAndFormat = (
  date: string,
  fn: (date: Date, now: Date) => string | undefined
) => {
  try {
    const now = new Date();
    const date_ = parseJSON(date);
    return fn(date_, now);
  } catch (error) {
    // noop
  }
};

@Pipe({ name: 'ago' })
export class TimeAgoPipe implements PipeTransform {
  transform(date: string, suffix = 'ago'): string {
    const result = parseAndFormat(date, formatDistance);
    return result ? `${result} ${suffix}` : undefined;
  }
}

@Pipe({ name: 'relative' })
export class TimeRelativePipe implements PipeTransform {
  transform(date: string): string {
    return parseAndFormat(date, formatRelative);
  }
}

@Pipe({ name: 'localedate' })
export class LocaleDatePipe implements PipeTransform {
  transform(date: string): string {
    return new Date(date).toLocaleDateString();
  }
}

@Pipe({ name: 'localetime' })
export class LocaleTimePipe implements PipeTransform {
  transform(date: string): string {
    return new Date(date).toLocaleTimeString();
  }
}

@Pipe({ name: 'bytes' })
export class BytesPipe implements PipeTransform {
  transform(bytes: number): string {
    return prettyBytes(bytes || 0);
  }
}

@Pipe({ name: 'safeHtml' })
export class SafeHtml implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(html: string | null | undefined) {
    return this.sanitizer.bypassSecurityTrustHtml(html || '');
  }
}

@Pipe({ name: 'safeUrl' })
export class SafeUrl implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(url: string | null | undefined) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
}

@Pipe({ name: 'marked' })
export class MarkedPipe implements PipeTransform {
  transform(value: string): string {
    return marked.parseInline(value || '');
  }
}
