import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'softbrik-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent {
  @Input() backTitle = 'common.Back to table';
  @Output() onBack = new EventEmitter();

  goBack() {
    this.onBack.emit();
  }
}
