<div class="modal is-active animate-fade-in">
  <div (click)="cancel()" class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p *ngIf="message.title" class="modal-card-title">
        {{ message?.title | async }}
      </p>
      <div *ngIf="message.showCancel" class="delete" (click)="cancel()"></div>
    </header>

    <div class="modal-card-body">
      <ng-container *ngIf="message?.type == 'confirm'">
        <div [innerHTML]="message?.content | async"></div>
      </ng-container>
    </div>

    <div class="modal-card-foot is-justify-content-flex-end">
      <button
        (click)="cancel()"
        *ngIf="message?.showCancel"
        type="button"
        class="mr-1 button is-text"
      >
        <span *ngIf="message.cancelLabel">{{
          message.cancelLabel | async
        }}</span>
        <span *ngIf="!message.cancelLabel" translate>common.Cancel</span>
      </button>
      <button
        (click)="ok()"
        *ngIf="message?.showOk"
        type="button"
        class="button is-success"
      >
        <span *ngIf="message.okLabel">{{ message.okLabel | async }}</span>
        <span *ngIf="!message.okLabel" translate>common.OK</span>
      </button>
    </div>
  </div>
</div>
