import { BrikId, HelpItem } from '@softbrik/data/models';

const brik = BrikId.Survey;

const faqs: HelpItem[] = [
  {
    title: 'What is Survey Brik?',
    content:
      'Survey Brik lets you create short, voice enabled, logic driven customer surveys within minutes. These Voice (and Text) enabled Surveys can be shared with customers as web-links embedded in e-mails, website, apps or QR. Customers click or scan the link and fill the surveys. Our Machine learning transcribes and translates the feedback along with understanding customer sentiments and emotions. You can get standard or customized dashboards with high response rate and detailed customer intelligence for better evidence based decisions eliminating guesswork.',
    brik,
  },
  {
    title: 'How to use the Survey Brik?',
    content:
      '<p>Check Survey Academy to learn how you can create, share and analyze Voice, Smart Text and Logic driven Surveys in a few minutes.</p><p><a href="https://softbrik.com/courses/survey-brik-course/">Survey Acadamy</a></p>',
    brik,
  },
  {
    title: 'How can I use Survey Brik in daily busineess?',
    content:
      '<p>Survey Brik has been used to digitalize many traditional surveys in Healthcare, Telecommunication, E-Commerce, Event Feedback to name a few. Check our Solutions and Blog Articles to get inspired on how to use our Surveys to capture better, open-ended, higher rate of Customer response.</p><p><a href="https://softbrik.com/">Solutions</a></p><p><a href="https://softbrik.com/articles">Blog articles</a></p>',
    brik,
  },
  {
    title: 'Can I integrate Survey Brik to my customer channels?',
    content:
      '<p>You can either embed any Voice Survey as a web-link in email or website by simply copy-pasting the links or integrate to your app environment using the Softbrik API. Check our Developper Guide section on how to use the API.</p><p><a href="https://softbrik.stoplight.io/">Developer guide</a></p>',
    brik,
  },
];

export default faqs;
