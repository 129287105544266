<div class="modal is-active">
  <div (click)="close()" class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title" translate>component.qrmodal.QR and Link</p>
      <button class="delete" aria-label="close" (click)="close()"></button>
    </header>
    <section
      class="modal-card-body"
      style="display: flex; justify-content: center"
    >
      <softbrik-qr-card
        [factory]="factory"
        [request]="requestEmitter"
        [filename]="filenameGetter"
        (ready)="onReady($event)"
      ></softbrik-qr-card>
    </section>
    <footer class="modal-card-foot" style="justify-content: center">
      <ng-container *ngIf="details">
        <button class="button is-info" (click)="requestAction('copy')">
          <span class="icon is-small">
            <i class="fa fa-copy"></i>
          </span>
          <span translate>component.qrmodal.Copy link</span>
        </button>
        <button class="button is-info" (click)="requestAction('print')">
          <span class="icon is-small">
            <i class="fa fa-print"></i>
          </span>
          <span translate>component.qrmodal.Print QR</span>
        </button>
        <button class="button is-info" (click)="requestAction('download')">
          <span class="icon is-small">
            <i class="fa fa-download"></i>
          </span>
          <span translate>component.qrmodal.Download QR</span>
        </button>
        <button class="button is-info" (click)="requestAction('script')">
          <span class="icon is-small">
            <i class="fa fa-code"></i>
          </span>
          <span translate>component.qrmodal.Copy Script</span>
        </button>
      </ng-container>
    </footer>
  </div>
</div>
