import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface MenuItem {
  name?: string;
  link?: string;
  linkMethod?: 'href' | 'js' | 'event' | 'none';
  linkOutlet?: string;
  type?: 'text' | 'text-icon' | 'icon' | 'image' | 'spacer';
  values?: MenuItem[];
  slot?: 'left' | 'right';
  data?: string | object;
  tag?: 'a' | 'button' | 'div' | 'span';
  class?: string;
  style?: string;
  event?: string;
  eventValue?: string;
}

@Injectable({
  providedIn: 'root',
})
export class NavService {
  public navigationTop = new BehaviorSubject<MenuItem[]>([]);
  public navigationSidebar = new BehaviorSubject<MenuItem[]>([]);
  sidebarHandler: (event: MenuItem) => any;
  topHandler: (event: CustomEvent) => any;

  clearSidebar() {
    this.navigationSidebar.next([]);
  }

  setSidebarNavigation(
    items: MenuItem[],
    sidebarHandler?: (event: MenuItem) => any
  ) {
    this.navigationSidebar.next(items);
    this.sidebarHandler = sidebarHandler;
  }

  setTopNavigation(items: MenuItem[]) {
    this.navigationTop.next(items);
  }
}
