import { ArrayTransformer } from './ArrayTransformer';
import { Transformer } from './Transformer';

export class CsvTransformer implements Transformer<string> {
  quote = '"';
  sep = ',';
  eol = '\r\n';

  quoteRegex = new RegExp(this.quote, 'g');
  sepRegex = new RegExp(this.sep, 'g');
  eolRegex = new RegExp('\r?\n', 'g');

  keyMap: Map<string, string>;
  transformer: ArrayTransformer;

  constructor({
    keyMap,
    transformer,
  }: {
    keyMap: Map<string, string>;
    transformer: ArrayTransformer;
  }) {
    this.keyMap = keyMap;
    this.transformer = transformer;
  }

  *transform() {
    const escape = this.escape.bind(this);
    for (const row of this.transformer.transform()) {
      yield row.map(escape).join(this.sep);
    }
  }

  /**
   * @credit https://github.com/silverwind/save-csv/blob/master/save-csv.js
   * @see https://www.rfc-editor.org/rfc/rfc4180
   */
  private escape(value: any) {
    this.quoteRegex.lastIndex = 0;
    this.sepRegex.lastIndex = 0;
    this.eolRegex.lastIndex = 0;
    let value_ = '';

    if (typeof value === 'string') {
      value_ = value;
    } else if (value !== null && value !== undefined) {
      value_ = JSON.stringify(value);
    }

    let quoted = false;

    if (this.quoteRegex.test(value_)) {
      value_ =
        this.quote +
        value_.replace(this.quoteRegex, this.quote + this.quote) +
        this.quote;
      quoted = true;
    }

    if (!quoted && this.sepRegex.test(value_)) {
      value_ = this.quote + value_ + this.quote;
      quoted = true;
    }

    if (!quoted && this.eolRegex.test(value_)) {
      value_ = this.quote + value_ + this.quote;
    }

    return value_;
  }
}
