import { BrikId, HelpItem } from '@softbrik/data/models';

const faqs: HelpItem[] = [
  {
    title: 'What is the Allo Brik?',
    content:
      'Allo Brik is an application for you to track physical resources of your team or company using QR Code. You create item list, generate QR Code against them and update the item list everytime the location or owner changes, tracking through the QR Code easily.',
    brik: BrikId.Allo,
  },
  {
    title: 'How to use the Allo Brik?',
    content:
      '<p>Create new item with QR Code</p><p>Create a New Item</p><ol><li>Generate the QR Code of that item</li><li><code></code>Print the QR Code and add it as a label</li></ol><p>Update Item</p><ol><li>Update item when location or owner change</li><li><code></code>Any person can search the item on Item dashboard and just change the fields</li></ol><p>Allo Brik will remember this update when QR is scanned next time thus allowing seamless tracking</p>',
    brik: BrikId.Allo,
  },
  {
    title: 'How do I create a New Item?',
    content:
      ' <p>Items are company resources who need to be tracked across locations and owner </p> <ol> <li>Go the Items tab on the Allo Brik Dashboard</li> <li>Click on the + New Item sign on the right In the Item detail page, give the following:</li> <li>Code - Any unique identifier code that the Company uses for the item</li> <li>Name</li> <li>Description in maximum five to six words</li> <li>Assign Owner to a Person. If the Person is not on the dropdown list create a New Owner</li> <li>Assign Location. If the Location. is not on the dropdown list create a New Location</li> <li>Give a comment</li> <li>Enable the Item</li> <li>A QR Code with all the details will be generated for you to print and stick as label on the items</li></ol>',
    brik: BrikId.Allo,
  },
  {
    title: 'How do I create a New Location?',
    content:
      '<p>Locations are places where the company resources travel to for the projects</p><ol><li>Click on the Locations tab below the Items on the Allo Brik Dashboard</li><li>Click on the <strong>+ New Location</strong> sign on the right</li><li>In the Location detail page, give the following:</li><li>Name - An easily understandable location name for resources</li><li>Describe any specialty of this location or any additional details to avoid confusion</li><li>Save the Location</li><li>Keep the enabled box checked by default</l</ol>',
    brik: BrikId.Allo,
  },
  {
    title: 'How do I create a New Person?',
    content:
      '<p>Persons are people who maybe any one in your organization or beyond. They own items on Allo Brik.</p> <ol> <li>Click on the Persons tab below the Items on the Allo Brik Dashboard</li> <li>Click on the + New Person sign on the right &nbsp;</li> <li>In the Person detail page, give the following</li> </ol> <ul> <li>Name of the Individual</li> <li>Their email id is needed for sign-up</li> <li>Phone number for verification</li> </ul> <p>Keep the enabled box checked by default</p>',
    brik: BrikId.Allo,
  },
  {
    title: 'How can we scan the QR Data with our phones??',
    content:
      "<p>You can scan the QR code through any QR Scanner app.</p> <p>We are building an app to directly scan and edit item.</p> <p>To use the test version of the app please do 2 things:</p> <ol> <li>Download from <a href='https://play.google.com/apps/testing/com.softbrik.allo' target='_blank'>Google Play</a></li> <li><a href='mailto:support@softbrik.com?subject=Please add to Softbrik app testing group'>Drop us an email</a></li> </ol>",
    brik: BrikId.Allo,
  },
];

export default faqs;
