import { TokenInterceptor } from './interceptors/token.interceptor';
import { BrowserModule } from '@angular/platform-browser';
import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  isDevMode,
  APP_INITIALIZER,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AppService, DataAppDataServiceModule } from '@softbrik/data/services';
import { LoginComponent } from './login/login.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { ErrorInterceptor, AuthGuard, LayoutGuard } from './interceptors';
import { HelpComponent } from './help/help.component';
import { FilterHelpPipe } from './filter-help-pipe/filter-help.pipe';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SettingsComponent } from './settings/settings.component';
import { ChangePasswordComponent } from './settings/change-password/change-password.component';
import { ChangeLanguageComponent } from './settings/change-language/change-language.component';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';
import { UiCoreModule } from '@softbrik/ui/core';
import { clearKeys } from '@softbrik/shared/helpers';
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import {
  createBusinessAwareLoader,
  BusinessProvider,
  initTranslation,
} from '@softbrik/translate';
import { Location } from '@angular/common';
import { UpgradeComponent } from './upgrade/upgrade.component';
import { JoyrideModule } from 'ngx-joyride';

Bugsnag.start({
  apiKey: environment.bugsnagApiKey,
  onError: (event) => {
    // Skip reporting when in dev mode
    if (isDevMode()) {
      console.log(event);
      return false;
    }
    // If the error was unhandled clear keys
    if (event.unhandled) {
      clearKeys(['app_brik']);
    }
  },
});

const DEV_DOMAINS = ['ngrok.io', 'devops.boutique', 'localhost'];
const DEV_DOMAIN_REGEX = new RegExp(`(.*)${DEV_DOMAINS.join('|')}$`);
export const isDevDomain = (domain: string) => DEV_DOMAIN_REGEX.test(domain);
const customerAlias = isDevDomain(window.document.location.hostname)
  ? 'softdrinks'
  : window.document.location.hostname.split('.')[0];

// create a factory which will return the Bugsnag error handler
export function errorHandlerFactory() {
  return new BugsnagErrorHandler();
}

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LayoutGuard],
    data: {
      layout: 'plain',
    },
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [LayoutGuard],
    data: {
      layout: 'plain',
    },
  },
  {
    path: 'account/upgrade',
    component: UpgradeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'account/settings',
    component: SettingsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'account/change-password',
    component: ChangePasswordComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'account/change-language',
    component: ChangeLanguageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'admin',
    loadChildren: () =>
      import(`@softbrik/briks/admin`).then((m) => m.BriksAdminModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'contact',
    loadChildren: () =>
      import(`@softbrik/briks/contact`).then((m) => m.BriksContactModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'file',
    loadChildren: () =>
      import(`@softbrik/briks/file`).then((m) => m.BriksFileModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'stak',
    loadChildren: () =>
      import(`@softbrik/briks/stak`).then((m) => m.BriksStakModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'trust',
    loadChildren: () =>
      import(`@softbrik/briks/trust`).then((m) => m.BriksTrustModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'survey',
    loadChildren: () =>
      import('@softbrik/briks/survey').then((m) => m.BriksSurveyModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'trust-feedback',
    redirectTo: 'p/feedback',
  },
  {
    path: 'p/feedback',
    loadChildren: () =>
      import('@softbrik/public/feedback-form').then((m) => m.PublicFormModule),
    canActivate: [LayoutGuard],
    data: {
      layout: 'plain',
    },
  },
  {
    path: 'stak-reply',
    redirectTo: 'p/reply',
  },
  {
    path: 'p/reply',
    loadChildren: () =>
      import('@softbrik/public/stak-reply').then((m) => m.StakReplyModule),
    canActivate: [LayoutGuard],
    data: {
      layout: 'plain',
    },
  },
  {
    path: 'survey-submission',
    redirectTo: 'p/survey',
  },
  {
    path: 'p/survey',
    loadChildren: () =>
      import('@softbrik/public/survey').then((m) => m.PublicSurveyModule),
    canActivate: [LayoutGuard],
    data: {
      layout: 'plain',
    },
  },
  {
    path: 'verify/email',
    loadChildren: () =>
      import('@softbrik/public/email-verification').then(
        (m) => m.PublicEmailVerificationModule
      ),
    canActivate: [LayoutGuard],
    data: {
      layout: 'plain',
    },
  },
  // On feedback.softdrinks.com show as /signup
  ...(customerAlias === 'feedback'
    ? [
        {
          path: 'signup',
          loadChildren: () =>
            import('@softbrik/public/feedback-signup').then(
              (m) => m.PublicFeedbackSignupModule
            ),
          canActivate: [LayoutGuard],
          data: {
            topbar: true,
            layout: 'plain',
          },
        },
      ]
    : []),
  // On feedback.softdrinks.com show as /feedback-signup
  // this is primarily just to test
  ...(customerAlias === 'softdrinks'
    ? [
        {
          path: 'p/signup',
          loadChildren: () =>
            import('@softbrik/public/feedback-signup').then(
              (m) => m.PublicFeedbackSignupModule
            ),
          canActivate: [LayoutGuard],
          data: {
            topbar: true,
            layout: 'plain',
          },
        },
      ]
    : []),
];

/**
 * We disable stripping the final slash in case of file brik where the path is
 * also the path of files and folders.
 */
const stripTrailingSlash = Location.stripTrailingSlash;
Location.stripTrailingSlash = (url: string) =>
  url.startsWith('/file/browse') ? url : stripTrailingSlash(url);

export function appInitializerFactory(
  translateService: TranslateService
): () => Promise<any> {
  return () => initTranslation(translateService).pipe(take(1)).toPromise();
}

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    HelpComponent,
    FilterHelpPipe,
    ForgotPasswordComponent,
    SettingsComponent,
    ChangePasswordComponent,
    ChangeLanguageComponent,
    MessageDialogComponent,
    UpgradeComponent,
  ],
  imports: [
    BrowserModule,
    JoyrideModule.forRoot(),
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      relativeLinkResolution: 'legacy',
    }),
    FormsModule,
    ReactiveFormsModule,
    DataAppDataServiceModule,
    UiCoreModule,
    StoreModule.forRoot(
      {},
      {
        metaReducers: !environment.production ? [] : [],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      }
    ),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      useDefaultLang: true,
      isolate: false,
      loader: {
        provide: TranslateLoader,
        useFactory: createBusinessAwareLoader,
        deps: [HttpClient, BusinessProvider],
      },
    }),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    StoreRouterConnectingModule.forRoot(),
  ],
  providers: [
    AppService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService],
      multi: true,
    },
    { provide: BusinessProvider, useClass: BusinessProvider },
    { provide: ErrorHandler, useFactory: errorHandlerFactory },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [],
})
export class AppModule {}
