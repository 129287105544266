import { BrikId, HelpItem } from '@softbrik/data/models';

const faqs: HelpItem[] = [
  {
    title: 'What is the Trust Brik?',
    content:
      "<p>Trust Brik is a powerful tool to hear back from your end-customers. You create Items, generate QR labels for them and stick the printed QRs on the items. Your end customers can scan the QR through Softbrik's own QR app or any other QR app to give feedback or repeat sales orders. You can engage unlimited end-customers through Trust Brik for free.</p>",
    brik: BrikId.Trust,
  },
  {
    title: 'How to use the Trust Brik?',
    content:
      '<p><ol><li>Create a new item with QR code</li><li>Print the QR Code(s) with a label printer</li><li>Paste your QR code sticker(s) on your item</li><li>Your end-customers scan the QR to give feedback or sales re-orders</li><li>Study the average feedback for each item</li><li>See detailed feedback and escalate to Stak Brik as Customer issues to act</li></ol></p>',
    brik: BrikId.Trust,
  },
  {
    title: 'How do I create a New Item?',
    content:
      '<p><p>Items are products sold to end-customers. To create items: <ul><li>Go to the Items tab on the Trust Brik Dashboard</li><li>Click on the + New Item sign on the right in the item detail page, give the following:</li><li>Name</li><li>Description in 5-6 words max</li><li>Contact(Optional) If anyone in the team is the point of contact for the item</li></ul></p></p>',
    brik: BrikId.Trust,
  },
  {
    title: 'How do I ask my customers to give feedback',
    content:
      "All QR stickers have a 'Scan for Feedback' label attached to them. Your customers can scan through the Softbrik QR app or any other iOS or Android scanner app. You can share the Softbrik QR App with your customers: <a href='https://play.google.com/store/apps/details?id=com.softbrik.allo&gl=DE'>https://play.google.com/store/apps/details?id=com.softbrik.allo&gl=DE</a></p>",
    brik: BrikId.Trust,
  },
  {
    title: 'How do I analyze the feedback',
    content:
      '<p>You can see the feedback count and average ratings given by end-customers for each item. If you wish to dive in more details, press the All Feedback icon where you can see each and every individual feedback.</p>',
    brik: BrikId.Trust,
  },
  {
    title: 'How do I use the feedback?',
    content:
      "<p>You can use the generic feedback and insights to improve your product's experience. Specifically, if you choose to escalate the feedback as separate actionable issues to Stak Brik from all the Feedback page, your Stak Brik resolvers can act on them to ensure your end-customers get the best experience with your products and service building long term loyalty.</p>",
    brik: BrikId.Trust,
  },
];

export default faqs;
