/**
 * Apply styles to document
 */
export function appendStyles(content: string): HTMLStyleElement {
  const style = document.createElement('style');
  style.textContent = content;
  document.body.appendChild(style);
  return style;
}

/**
 * Export all functions as source and append to body
 */
export function exportScripts(
  fns: Record<string, Function>
): HTMLScriptElement {
  const script = document.createElement('script');
  script.textContent = Object.entries(fns)
    .map(
      // const ${fn.name} = ${fn.toString()};
      ([name, fn]) => {
        return `
         const ${name} = ${fn.toString()};
       `;
      }
    )
    .join('\n');
  document.body.appendChild(script);
  return script;
}

/**
 * Create template element
 */
export function createTemplate(content: string): HTMLTemplateElement {
  const template = document.createElement('template');
  template.innerHTML = content;
  return template;
}

export type SlotMapper<T> = (el: HTMLElement | Node[], key: string) => T;

/**
 * Map slots from parent into an key-value object where all none slot elements
 * are added to the the default slot.
 *
 * You can use this mapping to set innerHTML of the parent.
 *
 * See also slotsToStringMapper for simple string mapper.
 */
export function mapSlots(
  parent: HTMLElement
): { slots: { [key: string]: HTMLElement | Node[] }; map: Function } {
  const data = {
    slots: {},
    map: function <T>(callback: SlotMapper<T>): { [key: string]: T } {
      return Object.entries(this.slots).reduce(
        (mapped, entry: [string, HTMLElement | Node[]]) => {
          mapped[entry[0]] = callback(entry[1], entry[0]);

          return mapped;
        },
        {}
      );
    },
  };

  parent.querySelectorAll('[slot]').forEach((el) => {
    const prop = el
      .getAttribute('slot')
      .replace(/-(\w)/g, (_, $1) => $1.toUpperCase());
    data.slots[prop] = el;
  });

  // @ts-ignore
  data.slots.default = Array.from(parent.childNodes).filter((node: Node) => {
    return (
      node.nodeName === '#text' ||
      (node instanceof HTMLElement && !node.hasAttribute('slot'))
    );
  });

  return data;
}

export function slotsToStringMapper(
  el: HTMLElement | Node[],
  _key: string
): string {
  if (el instanceof HTMLElement) {
    return el.outerHTML;
  }
  if (Array.isArray(el)) {
    return el
      .map((el: Node) =>
        el instanceof HTMLElement ? el.outerHTML : el.textContent
      )
      .join('');
  }
}

export const createEvent = (name: string, detail: object) =>
  new CustomEvent(name, { bubbles: true, detail });
