import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  CalendarDate,
  CalendarSheet,
  create,
} from 'calendar-builder/dist/esm/main';

@Component({
  selector: 'softbrik-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DatePickerComponent),
    },
  ],
})
export class DatePickerComponent
  implements OnInit, OnChanges, ControlValueAccessor
{
  @Input() id: string | null;
  @Input() empty = 'component.datepicker.Select date';
  @Input() value: CalendarDate | [CalendarDate, CalendarDate] | null;
  @Input() range = false; // TODO implement
  @Input() after: Date | string | undefined;
  @Input() before: Date | string | undefined;
  @Output() valueChange = new EventEmitter<Date | null>();

  calendar: CalendarSheet;
  open = false;

  onChange: (
    currentValue: CalendarDate | [CalendarDate, CalendarDate] | null
  ) => void;

  ngOnInit(): void {
    this.today();
  }

  ngOnChanges(value: any) {
    if (value.value) {
      const {
        value: { currentValue },
      } = value;
      this.today(currentValue);
    }
  }

  writeValue(currentValue: CalendarDate | [CalendarDate, CalendarDate] | null) {
    this.value = currentValue;
    this.ngOnChanges({ value: { currentValue } });
  }

  registerOnChange(
    fn: (
      currentValue: CalendarDate | [CalendarDate, CalendarDate] | null
    ) => void
  ) {
    this.onChange = (value: Date) => {
      this.value = value;
      this.today(value);
      fn(value);
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  registerOnTouched() {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setDisabledState() {}

  get valueFormatted() {
    if (!this.value) return null;

    return Array.isArray(this.value)
      ? `${new Date(this.value[0] as Date).toLocaleDateString()} – ${new Date(
          this.value[1] as Date
        ).toLocaleDateString()}`
      : `${new Date(this.value as Date).toLocaleDateString()}`;
  }

  private get options() {
    return {
      firstDay: 1,
      ...(this.value
        ? {
            selection: Array.isArray(this.value)
              ? this.value
              : ([this.value, this.value] as [CalendarDate, CalendarDate]),
          }
        : {}),
      after: this.after,
      before: this.before,
    };
  }

  get weekdays() {
    return this.calendar.days.slice(0, 7).map((day) => {
      return {
        label: day.date.toLocaleString(window.navigator.language, {
          weekday: 'narrow',
        }),
        dayOfWeek: day.dayOfWeek,
      };
    });
  }

  get prevLabel() {
    return this.calendar.prev.toLocaleString(window.navigator.language, {
      month: 'long',
    });
  }

  get currentLabel() {
    return this.calendar.current.toLocaleString(window.navigator.language, {
      year: 'numeric',
      month: 'long',
    });
  }

  get nextLabel() {
    return this.calendar.next.toLocaleString(window.navigator.language, {
      month: 'long',
    });
  }

  close() {
    this.open = false;
  }

  clear() {
    this.valueChange.emit(null);
    this.onChange?.(null);
    this.close();
  }

  select(date: Date | null) {
    this.valueChange.emit(date);
    this.onChange?.(date);
    this.close();
  }

  prev() {
    this.today(this.calendar.prev);
  }

  today(date: Date = null, now: Date = null) {
    const now_ = now || new Date();
    const date_ = date || now_;
    this.calendar = create(date_, {
      ...this.options,
      now: now_,
    });
  }

  next() {
    this.today(this.calendar.next);
  }
}
