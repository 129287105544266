export const TRANSLATE_KEYS = {
  ACTION: 'common.Action',
  ACTIVE: 'common.Active',
  ADD_ITEM: 'common.Add {item}',
  ARE_YOU_SURE: 'common.Are you sure?',
  CREATED: 'common.Created',
  DEACTIVATE_ITEM: 'common.Deactivate {item}',
  DELETE_ITEM: 'common.Delete {item}',
  EDIT_ITEM: 'common.Edit {item}',
  ERROR: 'common.Error',
  ERROR_CREATING_ITEM: 'common.Error creating {item}',
  ERROR_DEACTIVATING_ITEM: 'common.Error deactivating {item}',
  ERROR_DELETING_ITEM: 'common.Error deleting {item}',
  ERROR_LOADING_ITEM: 'common.Error loading {item}',
  ERROR_REACTIVATING_ITEM: 'common.Error deactivating {item}',
  ERROR_UPDATING_ITEM: 'common.Error updating {item}',
  FORM_ERROR: 'common.Form error',
  FORM_ERROR_MSG: 'common.Form has errors or is incomplete',
  INACTIVE: 'common.Inactive',
  ITEM_CREATED: 'common.{item} created',
  ITEM_DEACTIVATED: 'common.{item} deactivated',
  ITEM_DELETED: 'common.{item} deleted',
  ITEM_DOES_NOT_EXIST: 'common.Requested {item} does not exist',
  ITEM_REACTIVATED: 'common.{item} reactivated',
  ITEM_UPDATED: 'common.{item} updated',
  NOT_FOUND: 'common.Not found',
  REACTIVATE_ITEM: 'common.Reactivate {item}',
  STATE: 'common.State',
  UPDATED: 'common.Updated',
};
