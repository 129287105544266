import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';

const SENTIMENT_STYLE = {
  ['']: 'hidden',
  ['POSITIVE']: 'fa fa-smile-o has-background-success',
  ['NEGATIVE']: 'fa fa-frown-o has-background-danger',
  ['NEUTRAL']: 'fa fa-meh-o has-background-warning has-text-warning-dark',
  ['MIXED']: 'fa fa-meh-o has-background-warning has-text-warning-dark',
};

@Component({
  selector: 'softbrik-issue-comment',
  templateUrl: './issue-comment.component.html',
  styleUrls: ['./issue-comment.component.scss'],
})
export class IssueCommentComponent implements OnInit {
  @ViewChild('player', { static: true }) player: ElementRef<HTMLMediaElement>;
  @Input('audio-url-provider') public audioUrlProvider: () => Promise<
    string | null
  >;
  @Input('class') public containerClass: string;
  @Input('color') public color: string;
  @Input('comment') public comment: any;
  @Input('empty-message') public emptyMessage: string;
  @Input('is-incoming') public isIncoming: boolean;
  @Output('error') public errorEmitter = new EventEmitter();
  public audioUrl: string | null | undefined;
  public playbackState: 'idle' | 'playing' | 'loading' | 'errored' = 'idle';

  constructor() {}

  ngOnInit(): void {}

  togglePlayback() {
    if (this.playbackState === 'playing') {
      this.stopPlayback();
    } else {
      this.startPlayback();
    }
  }

  get sentimentStyle() {
    return SENTIMENT_STYLE[this.comment.sentiment || ''];
  }

  async startPlayback() {
    if (this.playbackState === 'errored') return;
    try {
      this.playbackState = 'loading';
      const url = await this.getUrl();
      if (!url) {
        this.playbackState = 'errored';
        return;
      }
      this.player.nativeElement.src = url;
      await this.player.nativeElement.play();
      this.player.nativeElement.onpause = this.stopPlayback.bind(this);
      this.player.nativeElement.onended = this.onPlaybackEnded.bind(this);
      this.playbackState = 'playing';
    } catch (err) {
      if (['NotSupportedError', 'NotFoundError'].includes(err.name)) {
        this.playbackState = 'errored';
        this.errorEmitter.emit({
          message: 'File not found or not supported',
          error: err,
        });
      } else {
        this.playbackState = 'errored';
        this.errorEmitter.emit({ message: 'Error playing file', error: err });
      }
    }
  }

  stopPlayback() {
    this.player.nativeElement.pause();
    this.playbackState = 'idle';
    this.player.nativeElement.currentTime = 0;
  }

  onPlaybackEnded() {
    this.playbackState = 'idle';
    this.player.nativeElement.currentTime = 0;
  }

  private async getUrl(): Promise<string | null> {
    if (typeof this.audioUrl === 'undefined') {
      this.audioUrl = (await this.audioUrlProvider()) || null;
    }
    return this.audioUrl;
  }
}
