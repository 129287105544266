import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'softbrik-feature-lock',
  templateUrl: './feature-lock.component.html',
  styleUrls: ['./feature-lock.component.scss'],
})
export class FeatureLockComponent implements OnInit {
  @Input('inline') isInline: boolean;
  @Input('corner') isCorner: boolean;

  get inline() {
    return 'isInline' in this && this.isInline !== false;
  }

  get corner() {
    return !this.inline && 'isCorner' in this && this.isCorner !== false;
  }

  constructor() {}

  ngOnInit(): void {}
}
