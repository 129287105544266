import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserPlanFeatureResponseData } from '@softbrik/data/models';
import { AppService } from '@softbrik/data/services';
import { TranslateService } from '@softbrik/translate';

@Component({
  selector: 'softbrik-feature-modal',
  templateUrl: './feature-modal.component.html',
  styleUrls: ['./feature-modal.component.scss'],
})
export class FeatureModalComponent implements OnInit {
  @Input('message') message: string;
  @Input('data') data: UserPlanFeatureResponseData;
  @Input('right') right: boolean;
  @Output('close') close = new EventEmitter<void>();

  constructor(private app: AppService, private t: TranslateService) {}

  get alignRight() {
    return 'right' in this;
  }

  async ngOnInit() {
    this.app.analytics.emit({
      name: 'plan: upgrade modal shown',
      payload: {
        message: await this.t.get(this.message, this.data),
        data: this.data,
      },
    });
  }

  async closeModal() {
    this.app.analytics.emit({
      name: 'plan: upgrade modal closed',
      payload: {
        message: await this.t.get(this.message, this.data),
        data: this.data,
      },
    });
    this.close.emit();
  }

  async goToUpgrade() {
    this.app.goToUpgrade({
      message: await this.t.get(this.message, this.data),
      data: this.data,
    });
  }
}
