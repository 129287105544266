<div class="help__backdrop" (click)="toggleHelp($event)">
  <div class="help__container" [class.help__container--wide]="expanded">
    <div
      class="help__expand"
      [class.help__expand--wide]="expanded"
      [title]="expanded ? 'Contract' : 'Expand'"
      (click)="toggleExpand()"
    >
      <span class="icon"
        ><i
          class="fa fa-chevron-left"
          [class.fa-chevron-left]="!expanded"
          [class.fa-chevron-right]="expanded"
        ></i
      ></span>
    </div>
    <div class="help__header py-5 px-4">
      <span class="mr-3 icon"><i class="fa fa-lg fa-book"></i></span>
      <span class="help__header__title" translate>help.Support</span>
      <span class="mr-3 delete is-medium" (click)="closeHelp()"></span>
    </div>
    <div class="help__sections">
      <div
        class="mb-2 help__section"
        *ngFor="
          let item of app.helpItems | filter: { brik: app.currentBrik };
          let i = index
        "
      >
        <div class="help__section__title" (click)="toggleText(item)">
          <div class="icon is-small">
            <i
              class="fa"
              [class.fa-chevron-down]="!item?.show"
              [class.fa-chevron-up]="item?.show"
            ></i>
          </div>
          <div>
            <span>{{ item.title }}</span>
          </div>
        </div>

        <div
          class="help__section__body content"
          *ngIf="item?.show"
          [innerHTML]="item.content"
        ></div>
      </div>
    </div>
  </div>
</div>
