import { AppService } from '@softbrik/data/services';
import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HelpItem } from '@softbrik/data/models';

@Component({
  selector: 'softbrik-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent implements OnInit {
  public expanded = false;

  constructor(
    @Inject(DOCUMENT) public document: Document,
    public app: AppService
  ) {}

  ngOnInit(): void {}

  toggleExpand() {
    this.expanded = !this.expanded;
  }

  toggleText(item: HelpItem) {
    if (!item?.show) {
      item.show = true;
      return;
    }
    item.show = false;
  }

  toggleHelp(event: Event) {
    const backdrop = document.querySelector('.help__backdrop');
    event.stopPropagation();

    if (event.target === backdrop) {
      this.app.showHelp = !this.app.showHelp;
    }
  }

  closeHelp() {
    this.app.showHelp = false;
  }
}
