import { Component, OnInit, Input } from '@angular/core';
import { AppService } from '@softbrik/data/services';
import { MessageDialog } from '@softbrik/data/models';

@Component({
  selector: 'softbrik-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss'],
})
export class MessageDialogComponent implements OnInit {
  @Input() message: MessageDialog;

  constructor(public app: AppService) {}

  ngOnInit(): void {
    this.message = this.app.dialogMessage;
  }

  ok() {
    this.app.messageDialogResult.emit(true);
    this.app.isShowMessage = false;
  }

  cancel() {
    this.app.messageDialogResult.emit(false);
    this.app.isShowMessage = false;
  }
}
