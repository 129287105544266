import { CsvTransformer } from './CsvTransformer';
import { RowTransformer } from './RowTransformer';

export class CsvExporter {
  private eol = '\r\n';
  private transformer: CsvTransformer;

  constructor({
    keyMap,
    ...rest
  }: {
    keys: string[];
    keyMap: Map<string, string>;
    items: Record<string, any>[];
  }) {
    this.transformer = new CsvTransformer({
      keyMap,
      transformer: new RowTransformer({
        keyMap,
        ...rest,
      }),
    });
  }

  export() {
    const csvLines = [...this.transformer.transform()];
    return csvLines.join(this.eol);
  }
}
