import { Injectable } from '@angular/core';
import * as ngTranslate from '@ngx-translate/core';

type TranslateParam = string | number | string[] | number[];

@Injectable({
  providedIn: 'root',
})
export class TranslateService {
  constructor(public translate: ngTranslate.TranslateService) {}

  async get(
    key: string,
    params: Record<string, TranslateParam | Promise<TranslateParam>> = {}
  ) {
    for (const [key, value] of Object.entries(params)) {
      if (value instanceof Promise) {
        const value_ = await value;
        params[key] = value_;
      }
      if (Array.isArray(params[key])) {
        const value = params[key] as any[];
        params[key] = value.join(', ');
      }
    }
    return this.translate.get(key, params).toPromise();
  }
}
