<ng-container *ngIf="limit && (allowed || (!allowed && !useHideGuard))">
  <div class="is-relative">
    <!-- Guard: disable button for all guards except for 'modal' -->
    <button
      class="button {{ variant }} {{ size }}"
      (click)="emit($event)"
      translate
      [disabled]="
        (useDisableGuard || (!useModalGuard && useMessageGuard)) && !allowed
      "
    >
      {{ label }}
    </button>
    <softbrik-feature-lock
      *ngIf="!useModalGuard && !allowed"
    ></softbrik-feature-lock>
  </div>

  <!-- Guard: message (allowed) -->
  <div
    *ngIf="featureEnabled && useMessageGuard && allowMessage && allowed"
    class="help"
    translate
    [translateParams]="data"
  >
    {{ allowMessage }}
  </div>

  <!-- Guard: message (denied) -->
  <div *ngIf="useMessageGuard && !allowed" class="help">
    <span
      [translate]="
        useModalGuard ? leftMessage : typedDenyMessage || typedDenyMessage
      "
      [translateParams]="data"
    ></span>
    <div
      *ngIf="!useModalGuard"
      (click)="goToUpgrade()"
      class="has-text-primary has-text-weight-bold is-clickable"
      translate
    >
      common.Upgrade
    </div>
  </div>
  <!-- Guard: modal -->
  <softbrik-feature-modal
    *ngIf="useModalGuard && showModal"
    [message]="typedDenyMessage"
    [data]="data"
    (close)="showModal = false"
    right
  ></softbrik-feature-modal>
</ng-container>
