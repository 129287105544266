import { Injectable } from '@angular/core';
import { CsvExporter } from './export/CsvExporter';

@Injectable({
  providedIn: 'root',
})
export class ExportService {
  /**
   * Export items to CSV
   */
  public exportToCsv({
    filename,
    download = true,
    ...rest
  }: {
    download?: boolean;
    filename: string;
    keys: string[];
    keyMap: Map<string, string>;
    items: Record<string, any>[];
  }) {
    const exporter = new CsvExporter(rest);

    const content = exporter.export();

    if (download) {
      this.downloadCSV(content, filename);
    }

    return content;
  }

  /**
   * Helper function to get convert table headers data to transformer keyMap
   */
  keyMapFromHeaders(
    headers: { name: string; field: string }[],
    acceptList: string[] = [],
    denyList: string[] = []
  ) {
    const keyMap = new Map<string, string>();
    headers.forEach(({ name, field }) => {
      if (!denyList.includes(field) && acceptList.includes(field)) {
        keyMap.set(field, name);
      }
    });
    return keyMap;
  }

  private downloadCSV(csvContent: string, filename: string) {
    const blob = new Blob(['\ufeff' + csvContent], {
      type: 'text/csv;charset=utf-8',
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.click();
  }
}
