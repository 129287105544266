<div class="app__content">
  <div class="columns">
    <div class="column">
      <softbrik-page-header
        title="{{ 'settings.password.Change password' | translate }}"
      ></softbrik-page-header>
    </div>
  </div>

  <div class="columns">
    <div class="column is-4-desktop is-12-touchable">
      <form [formGroup]="form" (submit)="onSubmit()">
        <div class="field">
          <label class="label" for="password" translate>auth.Password</label>
          <div class="control">
            <input
              type="password"
              formControlName="password"
              id="password"
              class="input"
            />
          </div>
          <div
            *ngIf="f.password.errors?.required && f.password.touched"
            class="invalid-form-value"
            translate
          >
            auth.Password is required
          </div>
        </div>

        <div class="field">
          <label class="label" for="new_password" translate>
            settings.password.New password
          </label>
          <div class="control">
            <input
              type="password"
              id="new_password"
              formControlName="new_password"
              class="input"
            />
          </div>
          <div
            *ngIf="f.new_password.errors?.required && f.new_password.touched"
            class="invalid-form-value"
            translate
          >
            common.Required
          </div>
        </div>

        <div class="field">
          <label class="label" for="confirm_password" translate>
            settings.password.Confirm password
          </label>
          <div class="control">
            <input
              type="password"
              id="confirm_password"
              formControlName="confirm_password"
              class="input"
            />
          </div>
          <div
            *ngIf="
              f.confirm_password.errors?.required && f.confirm_password.touched
            "
            class="invalid-form-value"
            translate
          >
            common.Required
          </div>
          <div
            *ngIf="
              f.confirm_password.errors?.confirmed && f.confirm_password.touched
            "
            class="invalid-form-value"
            translate
          >
            auth.Password does not match
          </div>
        </div>
        <div class="buttons is-right">
          <button type="submit" class="button is-success" translate>
            common.Confirm
          </button>
          <button
            class="button is-text"
            (click)="app.navigate('account/settings')"
            translate
          >
            common.Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
