<div class="qr-card">
  <div *ngIf="loading" class="qr-card__loader">
    <i class="fa fa-refresh fa-2x fa-spin"></i>
  </div>
  <canvas
    #qr
    alt="QR code"
    class="qr-card__image animate-fade-in is-hidden"
  ></canvas>
  <a #download [href]="qrUrl" target="_blank" style="display: none"></a>
</div>
