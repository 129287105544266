<article class="issue-comment media">
  <audio #player></audio>
  <div
    class="media-content issue-comment__media-content"
    [ngStyle]="{ 'align-items': isIncoming ? 'flex-start' : 'flex-end' }"
  >
    <!-- header -->

    <div class="px-2 issue-comment__author">
      <div
        *ngIf="comment.is_internal"
        class="issue-comment__lock has-text-danger-dark"
        [ngClass]="{ 'issue-comment__lock--incoming': isIncoming }"
      >
        <i class="fa fa-lock"></i>
        <span class="ml-1" translate>component.comment.internal</span>
      </div>

      <div class="issue-comment__author-name">{{ comment.author }}</div>
      <div class="issue-comment__author-email">{{ comment.author_email }}</div>
      <i
        *ngIf="isIncoming"
        class="icon issue-comment__sentiment"
        [class]="sentimentStyle"
      ></i>
      <div class="issue-comment__date" [title]="comment.created_at">
        {{ comment.created_at | relative }}
      </div>
    </div>

    <!-- body -->

    <div
      class="media-body issue-comment__media-body px-3 py-3 mt-1"
      [ngStyle]="{ 'background-color': color ? color : '#fff' }"
    >
      <!-- comment text -->

      <div
        *ngIf="comment.comment"
        class="pr-4 issue-comment__media-body__texts"
        style="flex: 1"
      >
        <!-- transcribed comment -->
        <div
          *ngIf="comment.transcribed_comment"
          class="issue-comment__media-body__text-part"
        >
          <!-- icon -->
          <div class="icon is-small has-text-grey-light">
            <i class="fa fa-microphone"></i>
          </div>
          <!-- text -->
          <div>
            {{ comment.transcribed_comment }}
          </div>
        </div>

        <!-- text comment -->
        <div
          *ngIf="comment.text_comment"
          class="issue-comment__media-body__text-part"
        >
          <!-- icon -->
          <div class="icon is-small has-text-grey-light">
            <i class="fa fa-file-text-o"></i>
          </div>
          <!-- text -->
          <div>
            {{ comment.text_comment }}
          </div>
        </div>
      </div>

      <!-- empty message -->

      <div
        *ngIf="!comment.text_comment && !comment.transcribed_comment"
        class="pr-4 has-text-grey-light"
        style="flex: 1; font-style: italic"
      >
        {{ emptyMessage || 'Empty' }}
      </div>

      <!-- Playback -->

      <div
        *ngIf="comment.comment"
        class="issue-comment__playback"
        [class.cursor-pointer]="playbackState !== 'errored'"
        (click)="togglePlayback()"
      >
        <i
          class="fa"
          [ngClass]="{
            'fa-play animate-fade-in': playbackState === 'idle',
            'fa-spin fa-refresh': playbackState === 'loading',
            'fa-stop': playbackState === 'playing',
            'fa-exclamation': playbackState === 'errored'
          }"
        ></i>
      </div>
    </div>
  </div>
</article>
