import { ArrayTransformer } from './ArrayTransformer';

export class RowTransformer implements ArrayTransformer {
  keyMap: Map<string, string>;
  keys: string[];
  items: Record<string, any>[];

  constructor({
    keyMap,
    keys,
    items,
  }: {
    keyMap: Map<string, string>;
    keys: string[];
    items: Record<string, any>[];
  }) {
    this.keyMap = keyMap;
    this.keys = keys;
    this.items = items;
  }

  *transform() {
    yield this.keys.map((key) => this.keyMap.get(key) || key);
    for (const item of this.items) {
      yield this.keys.reduce((row, key) => {
        row.push(item[key]);
        return row;
      }, [] as any[]);
    }
  }
}
