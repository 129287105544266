import { Component, HostBinding, OnInit } from '@angular/core';
import { AppService } from '@softbrik/data/services';

@Component({
  selector: 'softbrik-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css'],
})
export class SettingsComponent implements OnInit {
  @HostBinding('class.page') isPage = true;

  constructor(public app: AppService) {}

  ngOnInit(): void {}
}
