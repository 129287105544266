import { isValidEmail } from '@softbrik/shared/helpers';
import { DOCUMENT } from '@angular/common';
import { AppService } from '@softbrik/data/services';
import { Component, OnInit, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'softbrik-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
})
export class ForgotPasswordComponent implements OnInit {
  public email = '';

  constructor(
    @Inject(DOCUMENT) public document: Document,
    public app: AppService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {}

  public t(key: string) {
    return this.translate.get(key).toPromise();
  }

  reset() {
    if (!this.email || !isValidEmail(this.email)) {
      this.app.notify(
        this.t('common.Error'),
        this.t('auth.Please provide a valid e-mail'),
        'error'
      );
      return;
    }

    this.app.is_loading = true;
    this.app
      .sendPasswordResetEmail(this.email, this.app.customerAlias)
      .subscribe(
        () => {
          this.app.notify(
            this.t('auth.E-mail sent'),
            this.t('auth.Password request submitted')
          );
          this.app.is_loading = false;
          this.app.navigate('');
        },
        (err: Error) => {
          this.app.notifyError(
            this.t('auth.Unable to request password reset'),
            err
          );
          this.app.is_loading = false;
        }
      );
  }
}
