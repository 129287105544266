<audio #player></audio>
<div
  class="issue-comment__playback"
  [class.cursor-pointer]="playbackState !== 'errored'"
  (click)="togglePlayback()"
>
  <i
    class="fa"
    [ngClass]="{
      'fa-play animate-fade-in': playbackState === 'idle',
      'fa-spin fa-refresh': playbackState === 'loading',
      'fa-stop': playbackState === 'playing',
      'fa-exclamation': playbackState === 'errored'
    }"
  ></i>
</div>
