<div class="control select" [class.is-loading]="isLoading">
  <select #select (change)="onChange(select.value)">
    <option value="" translate>common.Select</option>
    <option
      *ngFor="let item of items"
      [value]="item[valueKey]"
      [disabled]="!featureEnabled && !useModalGuard && item.limit.allowed"
    >
      {{ item.name }}
      {{ !featureEnabled || item.limit.allowed ? '' : '🔒' }}
    </option>
  </select>
</div>
<div
  *ngIf="featureEnabled && useMessageGuard && groupMessage && someNotAllowed"
  class="help"
  translate
>
  <softbrik-feature-lock inline></softbrik-feature-lock>
  <span class="ml-1" translate>{{ groupMessage }}</span>
  <span
    (click)="goToUpgrade()"
    class="has-text-primary has-text-weight-bold is-clickable"
    translate
  >
    common.Upgrade
  </span>
</div>
<!-- Guard: modal -->
<softbrik-feature-modal
  *ngIf="useModalGuard && showModal"
  [message]="denyMessage"
  [data]="translateParams"
  (close)="closeModal()"
></softbrik-feature-modal>
